import { Button, TextField } from "@mui/material";

import { ActionFunctionArgs, redirect } from "react-router";
import { useActionData, useLoaderData } from "react-router-typesafe";

import { getSessionState, requestToken } from "~/api";
import { FormContainer } from "~/components/FormContainer";
import { localize } from "~/localize";

export async function loader() {
	let state = await getSessionState();

	if (state.hasIdentity) {
		if (!state.isAuthenticated && state.tokenSent) {
			return redirect("/verify-token");
		} else if (state.isAuthenticated && !state.hasPersonalia) {
			return redirect("/registration");
		} else if (state.isAuthenticated && state.hasPersonalia) {
			return redirect("/courses");
		}
	}

	return { state };
}

const emailRegex = new RegExp(
	// eslint-disable-next-line no-useless-escape
	/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

export async function action({ request }: ActionFunctionArgs) {
	let formData = await request.formData();
	let email = formData.get("email");

	if (!email || typeof email !== "string" || !emailRegex.test(email)) {
		return localize("E-postadressen er ikke en gyldig e-post.", "E-mail address is not a valid e-mail.");
	}

	let state = await requestToken(email);

	if (state.error) {
		return state.error;
	}

	return redirect("/verify-token");
}

export function Component() {
	let { state } = useLoaderData<typeof loader>();
	let error = useActionData<typeof action>();

	return (
		<FormContainer error={error} language={state.language}>
			<TextField name="email" label={localize("E-post", "Email")} fullWidth />
			<Button size="large" variant="contained" type="submit" sx={{ mx: "auto" }}>
				{localize("Neste", "Next")}
			</Button>
		</FormContainer>
	);
}
